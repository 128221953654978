import React from 'react';
import Countdown from 'react-countdown';
import './App.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBBFjbLIyoy3Ux85-HAbfBzu2EBceXwMWo",
  authDomain: "henrigeorg-1f98b.firebaseapp.com",
  projectId: "henrigeorg-1f98b",
  storageBucket: "henrigeorg-1f98b.appspot.com",
  messagingSenderId: "686617516513",
  appId: "1:686617516513:web:31908cfa68ba1f53251aa0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const renderer = ({ days, hours, minutes, seconds }) => {
  return (
    <span>
      Georg trifft Henrike in {days} Tagen, {hours} Stunden, {minutes} Minuten und {seconds} Sekunden wieder
    </span>
  );
};

function App() {
  const targetDate = new Date(2023, 7, 21, 12, 0, 0); // Monate sind 0-basiert in JavaScript, also ist 7 für August

  return (
    <div className="app">
      <div className="countdown-text">
        <Countdown date={targetDate} renderer={renderer} />
      </div>
    </div>
  );
}

export default App;
